function getRandomArbitary(min, max) {
  return Math.random() * (max - min) + min;
}


// Vanilla JS
document.addEventListener('DOMContentLoaded', () => {
  /** Все в .header-pic в соотношении сторон 1:2*/
  const arrHeaderPic = document.querySelectorAll('.header-pic');

  [...arrHeaderPic].forEach(pic => {
    pic.style.height = pic.clientWidth / 2 + 'px';
  });

  // Переход по выбранному select
  if ( document.querySelector('.submenu-mobil') ) {
    let submenu = document.querySelector('.submenu-mobil__select');
    submenu.addEventListener('change', function() {
      let url = this.value;
      window.location.href = url
    });
  }


  // Вращение фиксированной кнопки вместе со скроллом
  if ( document.querySelector('[data-fix-btn]') ) {
    let rotateFixBtn = 0;
    let currentPosition = 0;

    function rotateBtn() {
      document.querySelector('[data-fix-btn]').style.transform = `rotate(${rotateFixBtn}deg)`;

      if(window.pageYOffset < currentPosition) rotateFixBtn = rotateFixBtn + 2.2;
      if(window.pageYOffset > currentPosition) rotateFixBtn = rotateFixBtn - 1.8;

      currentPosition = window.pageYOffset;
    }

    document.addEventListener('scroll', () => {
      rotateBtn();
    });

    document.addEventListener('touchmove', function(){
      rotateBtn();
    });

    setInterval(() => {
      document.querySelector('[data-fix-btn]').style.transform = `rotate(${rotateFixBtn}deg)`;
      rotateFixBtn = rotateFixBtn - 0.2;
    }, 15)
  }

  // Рандомные размеры картинок в направлениях
  {
    let directionsInfoPicList = document.querySelectorAll('.directions__img');

    directionsInfoPicList.forEach(item => {
      let cf = getRandomArbitary(0.8, 1.2);
      let randomWidth = 300 * cf;
      let randomHeight = 168 * cf;

      item.style.width = randomWidth + 'px';
      item.style.height = randomHeight + 'px';
    });

    /**
     * Смена информации при наведении на пункты списка
     */
    const listDirections = document.querySelectorAll('[data-link]');
    const arrContent = document.querySelectorAll('[data-wrap]');
    listDirections.forEach(item => {
      item.onmouseover = event => {

        /** Меняем активный пункт */
        listDirections.forEach(item => {
          item.classList.remove('active');
        });
        event.target.classList.add('active');

        /** Меняем активный блок инфы */
        const index = +event.target.dataset.link;
        arrContent.forEach(item => {
          item.classList.add('d-none');
        });
        arrContent[index].classList.remove('d-none');
      };
    });
  }

  // Swiper
  let oursProjects = new Swiper ('.ours-projects .swiper-container', {
    slidesPerView: 'auto',
    initialSlide: 1,
    centeredSlides: true,
    spaceBetween: 60,
    loop: true,
    //autoplay: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  });
});



// JQuery
$(document).ready(function () {
  // Плавная прокрутка до якоря
  $("a[href*='#']").click(function () {
    let elementClick = $(this).attr("href");
    let destination = $(elementClick).offset().top - 80;
    $("html:not(:animated),body:not(:animated)").animate({ scrollTop: destination }, 1100);
    return false;
  });

  // Авторесайз textarea
  autosize(document.querySelectorAll('textarea'));

  // ShowLine
  function scrollMove( frame) {
    const showLine = document.querySelector('.show-line');
    const wrapShowLine = showLine.querySelector('.show-line__wrap');
    let childList = wrapShowLine.children;
    let temp = 0;

    /** Если обертка пуста - ничего не делаем */
    if (!childList.length) return;

    /** Если элментов меньше 10 то дублируем первые в конец */
    if (!childList.length < 10) {
      let i = 0;
      while (i < childList.length) {
        if (childList.length < 10) {
          const newChild = childList[i].cloneNode(false);
          wrapShowLine.insertAdjacentElement('beforeend', newChild);
          childList = wrapShowLine.children;
        }
        i++;
        if (childList.length === 10) break;
      }
    }

    let randomMarginTop = 0;
    let cf = 1;
    let randomWidth = 275;
    let randomHeight = 155;


    [...childList].forEach(child => {
      randomMarginTop =  getRandomArbitary(0, 100);
      cf = getRandomArbitary(0.5, 1.5);
      randomWidth = randomWidth * cf;
      randomHeight = randomHeight * cf;

      child.style.width = randomWidth + 'px';
      child.style.height = randomHeight + 'px';
      child.style.marginTop = randomMarginTop + 'px';

      randomWidth = 275;
      randomHeight = 155;
    });

    showLine.insertAdjacentElement('beforeend', wrapShowLine.cloneNode(true));

    const widthBlock = wrapShowLine.clientWidth;

    function move() {

      const wrapShowLineFirst = showLine.querySelector('.show-line__wrap');

      /** Проверяем пора ли переносить блок */
      if (wrapShowLineFirst.offsetLeft + widthBlock === 0 ) {
        wrapShowLineFirst.style.marginLeft = 0;
        showLine.insertAdjacentElement('beforeend', wrapShowLineFirst.cloneNode(true));
        wrapShowLineFirst.remove();


        const wrapShowLineSecond = showLine.querySelectorAll('.show-line__wrap')[1];
        wrapShowLineSecond.style.marginLeft = widthBlock;

        temp = 0;
      }

      /** Само движение */
      temp--;
      wrapShowLineFirst.style.marginLeft = temp + 'px';
    }

    setInterval(move , 1000/frame);
  }
  if (document.querySelector('.show-line')) scrollMove(60);
});